const STATES = [
  { id: '01', abbr: 'AL', name: 'Alabama' },
  { id: '02', abbr: 'AK', name: 'Alaska' },
  { id: '04', abbr: 'AZ', name: 'Arizona' },
  { id: '05', abbr: 'AR', name: 'Arkansas' },
  { id: '06', abbr: 'CA', name: 'California' },
  { id: '08', abbr: 'CO', name: 'Colorado' },
  { id: '09', abbr: 'CT', name: 'Connecticut' },
  { id: '10', abbr: 'DE', name: 'Delaware' },
  { id: '11', abbr: 'DC', name: 'District of Columbia' },
  { id: '12', abbr: 'FL', name: 'Florida' },
  { id: '13', abbr: 'GA', name: 'Georgia' },
  { id: '15', abbr: 'HI', name: 'Hawaii' },
  { id: '16', abbr: 'ID', name: 'Idaho' },
  { id: '17', abbr: 'IL', name: 'Illinois' },
  { id: '18', abbr: 'IN', name: 'Indiana' },
  { id: '19', abbr: 'IA', name: 'Iowa' },
  { id: '20', abbr: 'KS', name: 'Kansas' },
  { id: '21', abbr: 'KY', name: 'Kentucky' },
  { id: '22', abbr: 'LA', name: 'Louisiana' },
  { id: '23', abbr: 'ME', name: 'Maine' },
  { id: '24', abbr: 'MD', name: 'Maryland' },
  { id: '25', abbr: 'MA', name: 'Massachusetts' },
  { id: '26', abbr: 'MI', name: 'Michigan' },
  { id: '27', abbr: 'MN', name: 'Minnesota' },
  { id: '28', abbr: 'MS', name: 'Mississippi' },
  { id: '29', abbr: 'MO', name: 'Missouri' },
  { id: '30', abbr: 'MT', name: 'Montana' },
  { id: '31', abbr: 'NE', name: 'Nebraska' },
  { id: '32', abbr: 'NV', name: 'Nevada' },
  { id: '33', abbr: 'NH', name: 'New Hampshire' },
  { id: '34', abbr: 'NJ', name: 'New Jersey' },
  { id: '35', abbr: 'NM', name: 'New Mexico' },
  { id: '36', abbr: 'NY', name: 'New York' },
  { id: '37', abbr: 'NC', name: 'North Carolina' },
  { id: '38', abbr: 'ND', name: 'North Dakota' },
  { id: '39', abbr: 'OH', name: 'Ohio' },
  { id: '40', abbr: 'OK', name: 'Oklahoma' },
  { id: '41', abbr: 'OR', name: 'Oregon' },
  { id: '42', abbr: 'PA', name: 'Pennsylvania' },
  { id: '44', abbr: 'RI', name: 'Rhode Island' },
  { id: '45', abbr: 'SC', name: 'South Carolina' },
  { id: '46', abbr: 'SD', name: 'South Dakota' },
  { id: '47', abbr: 'TN', name: 'Tennessee' },
  { id: '48', abbr: 'TX', name: 'Texas' },
  { id: '49', abbr: 'UT', name: 'Utah' },
  { id: '50', abbr: 'VT', name: 'Vermont' },
  { id: '51', abbr: 'VA', name: 'Virginia' },
  { id: '53', abbr: 'WA', name: 'Washington' },
  { id: '54', abbr: 'WV', name: 'West Virginia' },
  { id: '55', abbr: 'WI', name: 'Wisconsin' },
  { id: '56', abbr: 'WY', name: 'Wyoming' },
  { id: '60', abbr: 'AS', name: 'American Samoa' },
  { id: '66', abbr: 'GU', name: 'Guam' },
  { id: '69', abbr: 'MP', name: 'Northern Mariana Islands' },
  { id: '72', abbr: 'PR', name: 'Puerto Rico' },
  { id: '74', abbr: 'UM', name: 'U.S. Minor Outlying Islands' },
  { id: '78', abbr: 'VI', name: 'U.S. Virgin Islands' },
];
Object.freeze(STATES);

class State {
  static get ALL_STATES() {
    return STATES;
  }
}
export default State;
