import React from 'react';

import RingdownsTable from './RingdownsTable';

function Ringdowns() {
  return (
    <main>
      <h1>Ringdown History</h1>
      <RingdownsTable />
    </main>
  );
}

export default Ringdowns;
